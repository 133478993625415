<script>
import {db} from "../firebase";
import { collectionData } from "rxfire/firestore";
import { startWith } from "rxjs/operators";
import { auth, googleProvider } from "../firebase";
import { authState } from "rxfire/auth";
import {navigateTo} from "yrv";

import Profile from '../components/Profile.svelte'
import Alert from 'svelte-material-icons/Alert.svelte'
import Send from 'svelte-material-icons/Send.svelte'
import Cancel from 'svelte-material-icons/Cancel.svelte'
import Delete from 'svelte-material-icons/Delete.svelte'
import Flag from 'svelte-material-icons/Flag.svelte'
import moment from "moment";





 const query = db.collection("users").orderBy("created");
 const submissions = collectionData(query,"id").pipe(startWith([]));
 




//  Authentication scripts

let user;

  const unsubscribe = authState(auth).subscribe(u => (user = u));


  function login() {
    auth.signInWithRedirect(googleProvider);
  }


  function logOut() {
    auth
      .signOut()
      .then(function() {
        navigateTo('/');
        console.log("Sign-out successful");
      })
      .catch(function(error) {
        console.log("Error signing out");
      });
  }



</script>



<div class="w-full max-w-screen-lg m-auto bg-white rounded mt-4">

  {#if user}
      <div class="flex items-center space-x-4">
        <Profile uid={user.uid} photoURL={user.photoURL} displayName={user.displayName}  />
        <div class="ml-auto cursor-pointer" on:click={logOut}>Log Out</div>
      </div>
    {:else}
      <button class="button" on:click={login}>
        <span>Sign in with Google</span>
      </button>
    {/if} 
</div>

<pre>
  {#if $submissions.length > 0}     

    {#each $submissions as submission}

    {#if submission.optin}
      "{submission.id}", "{submission.name}", "{submission.email}", "{submission.phone}","{submission.address}","{submission.city}", "{submission.state}","{submission.zip}","{submission.optin}","{submission.sent}"<br/>
    {/if}
    {/each}      
  {/if}
</pre>

  
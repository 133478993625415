<script>
  import { Router, Route, Link, router, navigateTo } from "yrv";
  import {
    name,
    address,
    phone,
    email,
    letter,
    city,
    state,
    zip,
    optin,
    id
  } from "../stores.js";
  import moment from "moment";
  import Send from "svelte-material-icons/Send.svelte";

  $state = "UT";
  $city = "Ogden";
  $zip = "84403";
  let date = new Date();

  let letterHeader;
  let letterEl;
  let letterFooter;
  let validated;

  $: if (
    $name &&
    validatePhoneNumber($phone) &&
    $address &&
    validateEmail($email)
  ) {
    validated = true;
  } else {
    validated = false;
  }

  function composeLetter() {
    let temp = ""
     

    temp += letterHeader.innerHTML;
    temp += letterEl.innerHTML;
    temp += letterFooter.innerHTML;
    temp +=  "This email was sent on behalf of " + $name + " (" + $email + ") from the <a href='https://webercofiber.com'>Uintah Fiber Initiative Website</a>." + "<br/><br/>";
      
    $letter = temp;
  }

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validatePhoneNumber(number) {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return re.test(String(number).toLowerCase());
  }
</script>

<style>
  .signhere {
    @apply w-full mt-2 text-red inline-block;
  }

  @screen md {
  }

  input:not([type="checkbox"]) {
    @apply w-full;
  }
  .hilite {
    @apply bg-yellow-light;
  }

  label {
    @apply text-xs block mt-2;
  }

  .sendbutton {
    @apply block h-auto py-2 px-4 text-black text-xl font-bold shadow-md;
  }

  .sendbutton:hover:not(:disabled) {
    @apply shadow-lg bg-blue-gray text-white;
  }

  .sendbutton:disabled {
    @apply opacity-25;
  }
</style>

<div class="max-w-screen-lg m-auto">
<div>
 <button
      class="block mt-8 border-0 h-auto py-2 px-4 text-black  font-bold"
      on:click={() => {
        navigateTo('/');
      }}>
      ← Go Back
    </button></div>
  <h2 class="p-2 text-2xl text-black">Preview & Customize Email</h2>
  <p class="text-black p-2 pt-0">
    Modify the text below to customize or personlize to your specific interests
    or needs.
  </p>

  <div
    class="paper bg-white-trans75 pt-4 md:pt-8 mt-4 border-t-8 border-b-4 pb-4">
    <div class="md:grid md:grid-cols-3 lg:grid-cols-4 md:gap-8">
      <div class="p-2 md:col-span-1">
        <h2>Required Fields</h2>
        {#if validated}
          <p class="text-xs mb-4">
            <span class="">✓All Fields Complete</span>
          </p>
        {:else}
          <p class="text-red mb-4 text-xs">*All fields are required.</p>
        {/if}
        <p>

          <label>Your Name ({$id})</label>
          <input
            class=""
            type="text"
            bind:value={$name}
            placeholder="Enter your name" />
        </p>
        <p>
          <label>Street Address</label>
          <input
            class=""
            type="text"
            bind:value={$address}
            placeholder="Enter your street address" />
        </p>

        <div class="flex space-x-2">
          <div class="">
            <label>City</label>
            <input
              class=""
              type="text"
              bind:value={$city}
              placeholder="City"
              disabled />
          </div>

          <div class="">
            <label>State</label>
            <input
              class=""
              type="text"
              bind:value={$state}
              placeholder="Utah"
              disabled />
          </div>

          <div>
            <label>Zip</label>
            <input
              class=""
              type="text"
              bind:value={$zip}
              placeholder="84403"
              disabled />
          </div>
        </div>
        <div>
          <label>Phone Number</label>
          <input
            class=""
            type="text"
            bind:value={$phone}
            placeholder="Enter your phone number" />
 <span class="text-xs">
            {validatePhoneNumber($phone) ? '✓ Phone' : '(Enter valid phone number)'}
          </span>
        </div>

        <div>
          <label>Email</label>
          <input
            class=""
            type="text"
            bind:value={$email}
            placeholder="Enter your email address" />
          <span class="text-xs">
            {validateEmail($email) ? '✓ Email' : '(Enter valid email address)'}
          </span>
        </div>

        <div>
          <p class="mt-8 text-sm text-black">
            If you would like to be contacted in the future about
            updates to this initiative, please check the box below.
          </p>
          <p class="mt-4">
            <label class="inline-flex items-center space-x-2">
              <input
                class="form-checkbox h-6 w-6"
                type="checkbox"
                checked
                bind:value={$optin} />
              <span class="">Join mailing list</span>
            </label>
          </p>
        </div>
       
      </div>

      <div class="p-2 md:col-span-2 lg:col-span-3 mt-4 md:mt-0">

        <h2 class="mb-2">Message Preview</h2>
        <div class="bg-white shadow border border-gray-light p-4">

          <p class="hidden" bind:this={letterHeader}>
            {moment().format('dddd, MMMM Do YYYY')}
            <br />
            <br />      
            James H. "Jim" Harvey
            <br />
            Gage Froerer
            <br />
            Scott Jenkins
            <br />
          </p>

          <p
            class="p-2 border border-gray-light"
            contenteditable="true"
            bind:this={letterEl}>
            Dear Board of Commissioners:
            <br />
            <br />
            My name is
            <span class="hilite">{$name ? $name : 'Your Name Here'}</span>
            and I live in the Uintah Highlands at
            <span class="hilite">
              {$address ? $address : 'Your Address Here'}.
            </span>            
            <br />
            <br />
            As a citizen of Weber County, I am writing to ask you to support a
            community fiber network as a solution to inadequate and outdated
            Internet access in our neighborhood.
            <br />
            <br />
            There are very few options for Internet service in the Uintah
            Highlands Neighborhood. The services I am currently paying for are
            slow and unreliable. They do not provide an adequate solution for
            the needs we have.
            <br />
            <br />
            A fast and reliable fiber network is an essential utility in today’s
            world and is necessary for nearly every aspect of daily life in
            today’s society. This need has been intensified lately due to
            COVID-19 and physical distancing measures currently in our state.
            <br />
            <br />
            As your constituent and voter, I consider this issue a priority, and
            I ask you to support and develop legislation that will bring these
            improvements to our neighborhood.
            <br />
            <br />
            Sincerely,
          </p>
          <p class="cursor-wait mt-2" bind:this={letterFooter}>
            <br/>
            <br/>
            <span class="hilite">{$name ? $name : 'Your Name Here'}</span>
            <br />
            <span class="hilite">
              {$address ? $address : '123 Your Address Here'}
            </span>
            <br />
            {$city}, {$state} {$zip}
            <br />
            <span class="hilite">
              {$email ? $email : 'youremail@here'}
              <br />
              <span class="hilite">
                {$phone ? $phone : 'your phone number here'}
              </span>                            
            </span>
            <br/>
            <br/>
            
          </p>

        </div>
      </div>
    </div>
    <p class="mt-4 text-sm">Information submitted on this website will not be used for commercial purposes and will only be sent to Weber County
            officials. In order to prevent abuse of this website, all submissions will be reviewed before messages are sent. You may send this message from your own email client by copying the message and sending to the commissioners yourself. Their addresses and information can be found here <a href="https://www.webercountyutah.gov/County_Commission/">here</a>. </p>
  </div>

  <div class="mt-4">
   
 <div class="mt-4">

          <button
            class="sendbutton"
            on:click={() => {
              composeLetter();
              navigateTo('/#complete');
            }}
            disabled={!validated}>
            <div
              class="flex items-center justify-center mx-auto space-x-4
              text-center">
              <span>Send Email</span>
              <Send />
            </div>
          </button>

        </div>
    <!-- <button class="mt-8 text-left w-auto">Print Letter</button> -->
  </div>

</div>

<script>
  import { db } from "../firebase";
  import firebase from '@firebase/app';
  import '@firebase/firestore'
  
  
  import {
    name,
    address,
    phone,
    city,
    state,
    zip,
    email,
    letter,
    id,
    petition,
  } from "../stores.js";
  import { navigateTo } from "yrv";

  let promise = saveDocument();

  function incrementCounter(){
      
    const statsRef = db.collection('stats').doc('--stats--').update({submitted: firebase.firestore.FieldValue.increment(1)});
  }
 

  async function saveDocument() {
   
    if ($name && $address && $phone && $email && $letter) {
      
      const data = {
        sent: false,
        optin: true,
        name: $name,
        address: $address,
        city: $city,
        state: $state,
        zip: $zip,
        phone: $phone,
        email: $email,
        letter: $letter,        
        created: Date.now()        
      }
             
      const docRef = await db.collection("users").add(data);   
      incrementCounter();
      
      
      
      

          
      
      $id = docRef.id;

    } else {
     
      navigateTo("/#step1");
    }
  }
</script>

<style>
  .paper {
    @apply border border-gray-light shadow p-8 mt-8;
  }
</style>

<div class="max-w-screen-lg m-auto xl:w-2/3 p-2">
  {#await promise}
    <p class="text-xl">Saving email…</p>
  {:then}


    <h1 class="text-2xl">Thank you. </h1>
    <p class="text-xl">
      Your email has been queued for review and delivery. To prevent spam and abuse of this system, your submission will be reviewed
      before being sent. 

    </p>
    <p class="text-xl mt-4">
      Allow 1-2 days for delivery. A copy will be emailed to your address.
    </p>
    {#if !$petition}
    <!-- Show block for users to sign notice of interest -->
    <!-- <p class="text-xl mt-4">
      Please add your name to the notice of interest.
      </p>
 <button
      class="self-end mt-2 block h-auto py-2 px-4 text-black text-xl font-bold shadow-md hover:shadow-lg hover:bg-black hover:text-white"
      on:click={() => {
        navigateTo('/#petition');
      }}>
      Add Your Name →
    </button> -->
    {/if}

  {:catch error}
    <p class="text-red">{error.message}</p>
  {/await}
</div>

import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';



var firebaseConfig = {
  apiKey: "AIzaSyDlQB4gsVlaDmTafsGOkxtJwb7Dl3P7RUI",
  authDomain: "uintah-highlands-fiber.firebaseapp.com",
  databaseURL: "https://uintah-highlands-fiber.firebaseio.com",
  projectId: "uintah-highlands-fiber",
  storageBucket: "uintah-highlands-fiber.appspot.com",
  messagingSenderId: "330315815020",
  appId: "1:330315815020:web:e0d4b1846964ce13c42049",
  measurementId: "G-7B128TYQEN"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const db = firebase.firestore();
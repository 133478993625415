import { writable } from 'svelte/store';

export const name = writable();
export const address = writable();
export const city = writable();
export const state = writable();
export const zip = writable();
export const phone = writable();
export const email = writable();
export const letter = writable();
export const lettertop = writable();
export const optin = writable();
export const id = writable();
export const petition = writable();




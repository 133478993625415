<script>
import {db} from "../firebase";
import { collectionData } from "rxfire/firestore";
import { startWith } from "rxjs/operators";
import { auth, googleProvider } from "../firebase";
import { authState } from "rxfire/auth";
import {navigateTo} from "yrv";

import Profile from '../components/Profile.svelte'
import Alert from 'svelte-material-icons/Alert.svelte'
import Send from 'svelte-material-icons/Send.svelte'
import Cancel from 'svelte-material-icons/Cancel.svelte'
import Delete from 'svelte-material-icons/Delete.svelte'
import Flag from 'svelte-material-icons/Flag.svelte'
import moment from "moment";





 const query = db.collection("users").orderBy("created");
 const submissions = collectionData(query,"id").pipe(startWith([]));
 

 function markRead(item){
   try{
     db.collection('users').doc(item).update({sent:true});
   }catch (error){
     console.error(error);
   }
 }

   function incrementSentCounter(){
      
    const statsRef = db.collection('stats').doc('--stats--').update({sent: firebase.firestore.FieldValue.increment(1)});
  }

function sendEmail(item){
  // working - need to add the rest of the email information to the script here.
    
    item.message ={
      subject: item.name+" asks you to support a fiber network in Uintah Highlands Neighborhood.",
      html: item.letter,
    }
    // item.to = ["john.dilworth@gmail.com"]
    item.to = ["Jim Harvey <jHarvey@co.weber.ut.us>", "Gage Froerer <gfroerer@co.weber.ut.us>", "Scott Jenkins <sjenkins@co.weber.ut.us>"]
    item.cc = [item.email,"admin@webercofiber.com"]    
    item.from = "Uintah Fiber Initiative <admin@webercofiber.com>"
    item.replyTo = item.name+" <"+item.email+">"

  try{
    db.collection('emails').add(item);    
    incrementSentCounter();
  }catch (error){
    console.error(error);
  }
}



function deleteItem(item){
  db.collection('users').doc(item).delete();
}


function flag(item,flag){

  var theFlag;
  if (flag == true){
    theFlag=false;
  }else{
    theFlag=true;
  }

  console.log("flagging"+" "+theFlag);
  
 try{
   db.collection('users').doc(item).update({flag: theFlag });
 }catch (error){
   console.error(error);
 }
 }



//  Authentication scripts

let user;

  const unsubscribe = authState(auth).subscribe(u => (user = u));


  function login() {
    auth.signInWithRedirect(googleProvider);
  }


  function logOut() {
    auth
      .signOut()
      .then(function() {
        navigateTo('/');
        console.log("Sign-out successful");
      })
      .catch(function(error) {
        console.log("Error signing out");
      });
  }



</script>



<div class="w-full max-w-screen-lg m-auto bg-white rounded mt-4">

  {#if user}
      <div class="flex items-center space-x-4">
        <Profile uid={user.uid} photoURL={user.photoURL} displayName={user.displayName}  />
        <div class="ml-auto cursor-pointer" on:click={logOut}>Log Out</div>
      </div>
    {:else}
      <button class="button" on:click={login}>
        <span>Sign in with Google</span>
      </button>
    {/if} 



  {#if $submissions.length > 0}

  <h1 class="my-8 text-xl text-black">Unsent Emails</h1>
  <ul>
    {#each $submissions as submission}

    {#if !submission.sent}
    <li class="shadow p-2 mb-2 border border-gray-xlight">
    <div class="flex items-center">
    <div class="flex items-center space-x-4">
      <span class="font-bold">{submission.name}</span>
      <span>{submission.email}</span>
      <span class="text-xs">{ moment(submission.created).format('MMMM Do hh:mm')}</span>
    </div>
    <div class="flex items-center space-x-2 ml-auto">
    
      <button class="flex items-center space-x-2 ml-4 py-1" on:click={markRead(submission.id), sendEmail(submission)}><Send size="1em"/><span>Approve & Send</span> </button>
      <button class="flex items-center space-x-2 ml-4 py-1" on:click={deleteItem(submission.id)}><Delete size="1em"/><span>Delete</span> </button>
    
   
      
      <span class="ml-auto rounded-full hover:bg-gray-xlight cursor-pointer p-2" on:click={flag(submission.id, submission.flag)}>
        {#if submission.flag}
          <Flag size="1em" color="red" />
        {:else}
          
            <Flag size="1em" color="black" />          
        {/if}
      </span>

    </div>
  </div>
  <div>
  <details class="bg-white rounded">
    <summary class="bg-white block w-full text-xs">Details</summary>
    <div class="mt-2">
    <ul class="">    
    <!-- <li>{submission.name}</li>
    <li>{submission.email}</li>
    <li>{submission.phone}</li>
    <li>{submission.address}</li>
    <li>{submission.city}</li>
    <li>{submission.state}</li>
    <li>{submission.zip}</li>     -->
    <li>{@html submission.letter}</li>    
    </ul>

    
  </details>    
  </div>
  </li>
  {/if}
    {/each}
    
    </ul>

  {/if}

  </div>